import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import "./css/book.css";
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Yukon from "./image/form/yukonxl.webp";
import MKT from "./image/form/mkt.png";
import Denali from "./image/form/Denali.png";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useState } from 'react';
// export const Book = () => {
//     const form = useRef();
  
//     const sendEmail = (e) => {
//       e.preventDefault();
  
//       emailjs
//         .sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', form.current, {
//           publicKey: 'YOUR_PUBLIC_KEY',
//         })
//         .then(
//           () => {
//             console.log('SUCCESS!');
//           },
//           (error) => {
//             console.log('FAILED...', error.text);
//           },
//         );
//     };
  
//     return (
//         <div> <form ref={form} onSubmit={sendEmail}>
//         <marquee><b> Sorry for the inconvinence but due to some technical problem following form is not working. please call us at 204 960-6662 or email us at info@rainbowlimos.ca for bookings/enquiries</b> </marquee>
//     <div className='Book-Wrapper'>
//     <h1>Reservations</h1>
//     <div className='Book-Wrapper-form'>
 
//     <h2>Step 1 - Enter Details:    </h2>
//         <div className='form-content'>
            
//             <Form.Group className='mb-3'>
//             <Form.Label>Name:</Form.Label>
//                 <Row>
//                     <Col>
//                     <Form.Control placeholder="First name" name="from_first_name"/>
//                     </Col>
//                     <Col>
//                     <Form.Control placeholder="Last name" name="from_last_name"/>
//                     </Col>
//                 </Row><br></br>
//                 <Row>
//                     <Col>
//                     <Form.Label>Email:</Form.Label>
//                     <Form.Control placeholder="Email@example.com" name="from_email"/>
//                     </Col>
//                     <Col>
//                     <Form.Label>Phone:</Form.Label>
//                     <Form.Control type='phone' placeholder="123-123-1234" name="from_phone" />
//                     </Col>
//                 </Row><br></br>
//                 <Row>
//                     <Col>
//                     <Form.Label>Pickup date:</Form.Label>
//                     <Form.Control type="date" name="pickup_date"/>
//                     </Col>
//                     <Col>
//                     <Form.Label>Pickup time:</Form.Label>
//                     <Form.Control type="time" name="pickup_time" />
//                     </Col>
//                 </Row><br></br>
                

            
//             <Form.Label>Trip Details:</Form.Label>
//             <Form.Select aria-label="Default select example" name="trip_type">
//                 <option>Please select</option>
//                 <option value="1">Airport Arrival</option>
//                 <option value="2">Airport Departure</option>
//                 <option value="3">Point-to-point</option>
//                 <option value="4">Wine Tour</option>
//                 <option value="5">Charter</option>
//                 <option value="6">Prom</option>
//                 <option value="7">Birthday</option>
//                 <option value="8">Transfer</option>
//                 <option value="9">Wedding</option>
//                 <option value="10">Round Trip</option>
//                 <option value="11">Other</option>
//             </Form.Select>
//             </Form.Group><br></br>
//             <Form.Group className='mb-3'>
//             <Form.Label>Pickup Location:</Form.Label>
//             <Form.Control
//                 type="address"
//                 id="address_txt"
//             />
//             </Form.Group><br></br>
//             <Form.Group className='mb-3'>
//             <Form.Label>Drop Off Location:</Form.Label>
//             <Form.Control
//                 type="address"
//                 id="drop_address_txt"
//             />
//             </Form.Group>

//         </div>
//     </div>
//     <div className='Book-Wrapper-map'>
//         <h2>Step 2 - Choose Vehicle:</h2>
//         <ul className='form-ul'>
            
//             <Form.Check 
//                 label={<img src={Yukon} className='Yukon' />}
//                 type='checkbox'
//                 id='yukon_xl'
//             /><p className='form-ul-label'><b>Yukon XL</b></p>
//             <Form.Check 
//                 label={<img src={MKT} className='Yukon' />}
//                 type='checkbox'
//                 id='mkt'
//             /><p className='form-ul-label'><b>Lincoln MKT</b></p>
//             <Form.Check 
//                 label={<img src={Denali} className='Yukon' />}
//                 type='checkbox'
//                 id='yukon_denali'
//             /><p className='form-ul-label'><b>Yukon Denali</b></p>
            
//         </ul>
//     </div>
//     </div>
//     <div className='form-btn'>
//         <Button variant="dark" type="submit" value="Send">
//                 Step 3 - Send enquiry! 
//         </Button>
        
   
//     </div>
//     </form>
//     </div>  
//     );
//   };



function Book() {

    const form = useRef();
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    const notify = () => toast("SUCCESS!");

    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs
        .sendForm('service_fthczmv', 'template_r0zf1pn', form.current, {
          publicKey: 'uNwHDO0mbPeO3rXxm',
        })
        .then(
          () => {
            console.log('SUCCESS!');
            confirmAlert({
                title: 'Thank you!',
                message: 'we have received your enquiry! we will get back to you within 24 hours. ',
                buttons: [
                    {
                      label: 'OK',
                    }
                  ]
              });
            
          },
          (error) => {
            console.log('FAILED...', error.text);
            confirmAlert({
                title: 'Sorry!',
                message: 'Due to some technical issues, we are not able to submit your form at this moment. Please email us at info@rainbowlimos.ca',
                buttons: [
                    {
                      label: 'OK',
                    }
                  ]
              });
          },
        );
    };
  
  return (
    
    <div id='Reservations'> <form ref={form} onSubmit={sendEmail}>
        <div className='Book-Wrapper'>
        <h1>Reservations</h1>
        <div className='Book-Wrapper-form'>
     
        <h2>Step 1 - Enter Details:    </h2>
            <div className='form-content'>
                
                <Form.Group className='mb-3'>
                <Form.Label>Name:</Form.Label>
                    <Row>
                        <Col>
                        <Form.Control placeholder="First name" name="from_first_name"/>
                        </Col>
                        <Col>
                        <Form.Control placeholder="Last name" name="from_last_name"/>
                        </Col>
                    </Row><br></br>
                    <Row>
                        <Col>
                        <Form.Label>Email:</Form.Label>
                        <Form.Control placeholder="Email@example.com" name="from_email"/>
                        </Col>
                        <Col>
                        <Form.Label>Phone:</Form.Label>
                        <Form.Control type='phone' placeholder="123-123-1234" name="from_phone" />
                        </Col>
                    </Row><br></br>
                    <Row>
                        <Col>
                        <Form.Label>Pickup date:</Form.Label>
                        <Form.Control type="date" name="pickup_date"/>
                        </Col>
                        <Col>
                        <Form.Label>Pickup time:</Form.Label>
                        <Form.Control type="time" name="pickup_time" />
                        </Col>
                    </Row><br></br>
                    

                
                <Form.Label>Trip Details:</Form.Label>
                <Form.Select aria-label="Default select example" name="trip_type">
                    <option>Please select</option>
                    <option value="1">Airport Arrival</option>
                    <option value="2">Airport Departure</option>
                    <option value="3">Point-to-point</option>
                    <option value="4">Wine Tour</option>
                    <option value="5">Charter</option>
                    <option value="6">Prom</option>
                    <option value="7">Birthday</option>
                    <option value="8">Transfer</option>
                    <option value="9">Wedding</option>
                    <option value="10">Round Trip</option>
                    <option value="11">Other</option>
                </Form.Select>
                </Form.Group><br></br>
                <Form.Group className='mb-3'>
                <Form.Label>Pickup Location:</Form.Label>
                <Form.Control
                    type="address"
                    id="address_txt"
                    name='pickup_location'
                />
                </Form.Group><br></br>
                <Form.Group className='mb-3'>
                <Form.Label>Drop Off Location:</Form.Label>
                <Form.Control
                    type="address"
                    id="drop_address_txt"
                    name='dropoff_location'
                />
                </Form.Group>
    
            </div>
        </div>
        <div className='Book-Wrapper-map'>
            <h2>Step 2 - Choose Vehicle:</h2>
            <ul className='form-ul'>
                
                <Form.Check 
                    label={<img src={Yukon} className='Yukon' />}
                    type='checkbox'
                    id='yukon_xl'
                    name='yukon'
                
                /><p className='form-ul-label'><b>Yukon XL</b></p>
                <Form.Check 
                    label={<img src={MKT} className='Yukon' />}
                    type='checkbox'
                    id='mkt'
                    name='mkt'
                /><p className='form-ul-label'><b>Lincoln MKT</b></p>
                <Form.Check 
                    label={<img src={Denali} className='Yukon' />}
                    type='checkbox'
                    id='yukon_denali'
                    name='denali'
                /><p className='form-ul-label'><b>Yukon Denali</b></p>
                
            </ul>
        </div>
        </div>
        <div className='form-btn'>
            <Button variant="dark" type="submit" value="Send">
                    Step 3 - Send enquiry! 
            </Button>
            
       
        </div>
        </form>
    </div>
  )
}

export default Book