import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import './css/navigation.css';

const Navigation = () => {
  return (
    <div>
      <Navbar collapseOnSelect expand="lg"  className="bg-body-tertiary" >
      <Container className='navbar_Title'>
        <Navbar.Brand href="#home">Rainbow Limousine Service</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="#Services">Services</Nav.Link>
            <Nav.Link href="#fleet">Our Fleet</Nav.Link>
           
          </Nav>
          <Nav>
          <Nav.Link href="#Reservations">Reservations</Nav.Link>
            <Nav.Link eventKey={2} href="#Contact">
              Contact Us
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </div>
  );
}

export default Navigation;
// <Navbar.Collapse id="responsive-navbar-nav">
//             <Nav className="me-auto">
//               <Nav.Link href="#features">Features</Nav.Link>
//               <Nav.Link href="#pricing">Pricing</Nav.Link>
//               <NavDropdown title="Dropdown" id="collasible-nav-dropdown">
//                 <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
//                 <NavDropdown.Item href="#action/3.2">
//                   Another action
//                 </NavDropdown.Item>
//                 <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
//                 <NavDropdown.Divider />
//                 <NavDropdown.Item href="#action/3.4">
//                   Separated link
//                 </NavDropdown.Item>
//               </NavDropdown>
//             </Nav>
//             <Nav>
//               <Nav.Link href="#deets">More deets</Nav.Link>
//               <Nav.Link eventKey={2} href="#memes">
//                 Dank memes
//               </Nav.Link>
//             </Nav>
//           </Navbar.Collapse>