import React from 'react';
import { Helmet } from 'react-helmet';

function seo() {
  return (
    <div>
        <Helmet>
        <title>Rainbow Limo Service!</title>
        <meta name="description" content="Discover unparalleled luxury with Rainbow Limo Service Winnipeg. From weddings to airport transfers, our professional chauffeurs and exquisite fleet ensure every journey is unforgettable. Book now!" />
        <meta name="keywords" content="luxury transportation,limo, winnipeg limo, limousine service, Winnipeg limo rental, airport transfer, wedding limo, corporate event transportation, party limo, graduation limo, night out limo, chauffeur service, sightseeing tour, Christmas light tour, special event transportation, executive car service, group transportation, limo fleet, professional chauffeurs, Manitoba limo service, elegant limousine" />
        <meta property="og:title" content="Experience Luxury | Rainbow Limo Service Winnipeg" />
        <meta property="og:description" content="Discover unparalleled luxury with Rainbow Limo Service Winnipeg. From weddings to airport transfers, our professional chauffeurs and exquisite fleet ensure every journey is unforgettable. Book now!" />
        <meta property="og:url" content="https://rainbowlimos.ca" />
      </Helmet>
    </div>
  )
}

export default seo

// <meta name="author" content="" />
// <meta property="og:image" content="https://example.com/image.jpg" />
///* <meta name="twitter:image" content="https://example.com/image.jpg" /> */
//<meta name="twitter:card" content="summary_large_image" />
{/* <meta name="twitter:title" content="My Page Title" /> */}
{/* <meta name="twitter:description" content="This is a description of my page" /> */}