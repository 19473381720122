import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import SEO from './Components/seo.js';
import Navigation from './Components/navigation.js';
import Wrapper from './Components/wrapper.js';
import Services from './Components/services.js';
import Book from './Components/Book.js';
import Fleet from './Components/fleet.js';
import Contact from './Components/contact.js';
import Footer from './Components/footer.js';
import {Helmet} from "react-helmet";

function App() {
  return (
    <div className="App">
        <Helmet>
          <title>Rainbow Limo Service</title>
          <meta name="description" content="Discover unparalleled luxury transportation in Winnipeg with Rainbow Limos. Our exquisite fleet and expert chauffeurs guarantee a sophisticated travel experience for weddings, proms, corporate events, and more. Trust Rainbow Limos to elevate your journey with elegance and comfort" />
          <meta property="og:title" content="Rainbow Limo Service | Winnipeg" />
          <meta property="og:description" content="Discover unparalleled luxury transportation in Winnipeg with Rainbow Limos. Our exquisite fleet and expert chauffeurs guarantee a sophisticated travel experience for weddings, proms, corporate events, and more. Trust Rainbow Limos to elevate your journey with elegance and comfort" />
        </Helmet> 
      <header className="App-header">
        <title>Rainbow Limo Service!</title>
        <SEO />
        <Navigation />
      </header>
      <br></br><br></br>
        <Wrapper />
        <Services />
        <Fleet />
        <Book />
        <Contact />
        <Footer />
    </div>
  );
}

export default App;
