import React from 'react';
import "./css/contact.css"

const Contact = () => {
  
  return (
    <div id='Contact' className='Contact-Container'>
      <div className='Contact-Content'>
      <h1>Contact Us!</h1>
      <h2><span className='h2-white'>Email us at</span> <a href="mailto:info@rainbowlimos.ca">Info@RainbowLimos.ca</a></h2>
      <h2><span className='h2-white'>or Call us @</span> <a href="tel:+12049606662" >204 960-6662</a></h2>
                                                                      
      </div>
    </div>
  );
}

export default Contact;
