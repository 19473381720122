import React from 'react';
import "./css/wrapper.css";
import Yukon from "./image/yukon.png";
import Button from 'react-bootstrap/Button';
import Scroll from 'react-scroll';
const ScrollLink = Scroll.ScrollLink;

const Wrapper = () => {
  return (
    <div className="Cover" id="home">
      <div className='cover-content'>
        <div className='cover-content-left'>
          <img src={Yukon} className='Yukon' />
          <hr className='hideHR'></hr>
        </div>
        <div className='cover-content-right'>
        <h1>Greetings!</h1>
          <p>Welcome aboard! Rainbow Limo Service Winnipeg will be your premier destination for luxurious transportation in the city! As the top choice for Airport, Party, and Wedding Limo Service in Winnipeg, we take pride in offering unparalleled professionalism and chauffeured excellence for all your special occasions.
              Are you searching for a limousine service to:
          </p>
          <ul>
            <li><b>Are you in need of reliable transportation to and from the airport?</b> Look no further. Rainbow Limo Service Winnipeg is here to ensure your journey begins and ends with comfort and convenience.</li>
            <li><b>Planning your dream wedding?</b> Let us make your day even more magical with our elegant limousine service. From your doorstep to the church and beyond, we'll ensure you arrive in style and sophistication.</li>
            <li><b>Looking to paint the town red with a memorable night out in Winnipeg?</b> Our limousines are at your service, providing a luxurious and safe way to experience the city's vibrant nightlife.</li>
            <li><b>Celebrating a graduation or organizing a corporate event?</b> Count on us for seamless transportation that reflects the professionalism and class of your occasion.</li>
            <li>No matter the event, if you require the touch of a professional chauffeur, Rainbow Limo Service Winnipeg is here to exceed your expectations and make every moment truly unforgettable.</li>
          </ul>
          <Button className="contact_btn" href='#Contact' variant="outline-light">Book Now!</Button>{' '}
        </div>
      </div>
    </div>
  );
}

export default Wrapper;
