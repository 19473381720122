import React from 'react';
import "./css/services.css";
const Services = () => {
  return (
    <div className='Services-container' id="Services">
      <div className='Services-content'>
        <h1>Services</h1>
        <p>
          Welcome to Rainbow Limo Service Winnipeg, your premier choice for luxury transportation in the heart of the city. From the bustling streets to the serene landscapes, we are here to elevate every moment of your journey. Whether you're embarking on a grand adventure, celebrating life's milestones, or simply indulging in a night of luxury, we have the perfect vehicle to match your needs.
        </p><p>
          Here, we pay attention to every detail, meticulously curating each aspect of your experience to guarantee your comfort and satisfaction. Our fleet is a showcase of luxury, boasting exquisite vehicles including Lincoln Town cars and GMC SUVs. From intimate gatherings to lavish celebrations, we cater to parties of all sizes, ensuring that every journey with us is nothing short of extraordinary.
        </p><p>
          As you embark on your journey with us, rest assured that our commitment to excellence knows no bounds. Our team of professional chauffeurs stands ready to provide you with a seamless and unforgettable experience, from the moment you book your reservation to the final farewell. With attention to detail and a dedication to exceeding your expectations, we strive to make every moment with us truly exceptional.
        </p><p>
          So whether you're jetting off to distant lands, exchanging vows with your beloved, or simply reveling in the joys of life, let Rainbow Limo Service be your trusted companion on the road to unforgettable memories. Explore our comprehensive list of services below, and embark on a journey of luxury and indulgence with us today.


        </p>
        <ul>
          <li><b>Airport Service:</b> Start your journey in style with our prompt and reliable airport transfers. Our chauffeurs will ensure a smooth and comfortable ride to or from the airport, allowing you to relax and focus on your travels.</li><br></br>
          <li><b>Weddings:</b> Make your special day even more memorable with our elegant wedding limousine service. From transporting the bridal party to whisking away the newlyweds, we'll add a touch of luxury to every moment.</li><br></br>
          <li><b>Birthday Parties:</b> Celebrate another year of life in style with our birthday limo packages. Whether it's a sweet sixteen or a milestone birthday, our spacious vehicles are perfect for gathering friends and family for a night of fun.</li>    <br></br>         
          <li><b>Graduations: </b>Commemorate your academic achievements with a luxurious ride to your graduation ceremony or celebratory dinner. Our professional chauffeurs will ensure you arrive in sophistication and style.</li><br></br>
          <li><b>Formal Dinners:</b> Impress your colleagues or clients with our executive car service for formal dinners and corporate events. Arrive at your destination feeling relaxed and ready to make a lasting impression.</li>         <br></br>      
          <li><b>Restaurant Trips:</b> Elevate your dining experience with our restaurant trip limo service. Whether it's a romantic dinner for two or a group outing with friends, arrive at the restaurant in comfort and sophistication.</li><br></br>
          <li><b>Girls Night Out:</b> Gather your girlfriends and enjoy a night of pampering and fun with our girls' night out limo packages. Let us take care of the transportation while you focus on making memories.</li>   <br></br>           
          <li><b>Photo Shoots:</b> Capture every moment in style with our photo shoot limousine service. Our luxurious vehicles provide the perfect backdrop for your photoshoot, ensuring you look and feel like a VIP.</li><br></br>
          <li><b>Bar Trips:</b> Experience Winnipeg's vibrant nightlife scene without worrying about driving or parking. Our bar trip limo service allows you to hop from one hot spot to another in ultimate comfort and safety.</li>   <br></br>                 
          <li><b>Stags/Stagettes:</b> Say goodbye to single life in style with our stag and stagette limo packages. Let loose with your friends as you enjoy a night of revelry and adventure before tying the knot.</li><br></br>
          <li><b>Sight-Seeing Trips:</b> Explore the beauty of Winnipeg and its surrounding areas with our sight-seeing limo tours. Sit back, relax, and let our knowledgeable chauffeurs take you on a journey to discover the city's hidden gems.</li>  <br></br>         
          <li><b>Christmas Light Tours:</b> Get into the holiday spirit with our magical Christmas light tours. Experience the festive decorations and twinkling lights of Winnipeg's neighborhoods from the comfort of our luxurious limousines.</li><br></br>
          <li><b>Out-of-Town Excursions:</b> Planning a getaway outside of Winnipeg? Let us take care of the transportation with our out-of-town excursion limo service. Sit back and enjoy the journey as we whisk you away to your destination.</li>   <br></br>    
          <li><b>Mid-Week Specials:</b> Take advantage of our exclusive mid-week specials and enjoy luxury transportation at unbeatable rates. Whether it's a weekday wedding or a spontaneous night out, we have the perfect package for you.</li><br></br>
        </ul>
        <p>Contact Rainbow Limo Service Winnipeg at 204-960-6662 or email us at Info@RainbowLimos.ca to book your occasion today. It will be our pleasure to serve you and exceed your expectations in every way.</p>
      </div>
    </div>
  );
}

export default Services;
