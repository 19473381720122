import React from 'react';
import "./css/footer.css"
const Footer = () => {
  return (
    <div className='footer-container'>
      <div className='footer-content'>
        <h2>Made with ❤️ in Friendly Manitoba</h2>
      </div>
    </div>
  );
}

export default Footer;
