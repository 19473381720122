import React from 'react';
import "./css/fleet.css";
import Carousel from 'react-bootstrap/Carousel';
import MKT1 from "./image/mkt/mkt1.jpg";
import MKT2 from "./image/mkt/mkt2.jpg";
import MKT3 from "./image/mkt/mkt3.jpg";
import MKT4 from "./image/mkt/mkt4.jpg";
import MKT5 from "./image/mkt/mkt5.jpg";

import Yukon1 from "./image/yukon/yukon1.jpg";
import Yukon2 from "./image/yukon/yukon2.jpg";
import Yukon3 from "./image/yukon/yukon3.jpg";
import Yukon4 from "./image/yukon/yukon4.jpg";
import Yukon5 from "./image/yukon/yukon5.jpg";

import Denali1 from "./image/denali/denali1.jpg";
import Denali2 from "./image/denali/denali2.jpg";
import Denali3 from "./image/denali/denali3.jpg";
import Denali4 from "./image/denali/denali4.jpg";
import Denali5 from "./image/denali/denali5.jpg";

const Fleet = () => {
  return (
    <div className='Fleet-container' id="fleet">
      <div className='Fleet-content'>
        <h1>Our Fleet: </h1>

        <h2>Lincoln MKT </h2>
        <p>Our Lincoln MKT Limo features a comfortable lounge seating and all the other amenities you look for in a limousine.
           The perfect limo rental for corporate shuttle duties, graduations, weddings, airport transfers, corporate events and anything else you want to do in style
           in City.
        </p>
          <Carousel className='carousel'>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={MKT1}
                alt="First slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={MKT2}
                alt="Second slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={MKT3}
                alt="Third slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={MKT4}
                alt="Third slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={MKT5}
                alt="Third slide"
              />
            </Carousel.Item>
          </Carousel>
          <br></br>
          <h2>2017 GMC Yukon XL </h2>
          <p>Our Yukon offers a luxury and smooth ride. All windows are tinted for your privacy & comfort.
             Capable of outputting premium audio and offering climate control. 7 Seater.
             The SUV is very quite from inside. </p>
          <Carousel>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={Yukon1}
                alt="First slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={Yukon2}
                alt="Second slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={Yukon3}
                alt="Third slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={Yukon4}
                alt="Third slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={Yukon5}
                alt="Third slide"
              />
            </Carousel.Item>
          </Carousel>
          <br></br>
          <h2>GMC Yukon XL Denali </h2>
          <p>Experience the pinnacle of luxury with the Yukon XL Denali. Indulge in refined elegance and supreme comfort, with premium amenities, advanced technology, and spacious seating for up to seven passengers. Elevate your journey with whisper-quiet interiors and unrivaled performance. Discover luxury redefined today.
             </p>
          <Carousel>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={Denali1}
                alt="First slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={Denali2}
                alt="Second slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={Denali3}
                alt="Third slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={Denali4}
                alt="Third slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={Denali5}
                alt="Third slide"
              />
            </Carousel.Item>
          </Carousel>
      </div>
    </div>
  );
}

export default Fleet;
